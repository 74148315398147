import React, { useEffect, useState, useMemo } from 'react';
import CustomerSelectorBox from './CustomerSelectorBox';
import CustomerAnchor from './CustomerAnchor';
import { useRootContext } from '../../contexts/Root';
import getAccessPolicies from './../../services/accessPolicies';

const MOBILE_WIDTH = 576;

const CustomerSelector = ({ widget, properties: propertiesProp }) => {
  const [isMobile, setIsMobile] = useState(false);
  const {
    v1: { navigation },
    v2: { authProvider }
  } = useRootContext();

  const properties = widget?.properties || propertiesProp;
  const [scopeList, setScopeList] = useState([]);
  const [requiredScopes, setRequiredScopes] = useState(
    properties?.requiredScopes as []
  );
  const [currentPathname, setCurrentPathname] = useState('');
  const [hasScopes, setHasScopes] = useState(false);

  // TODO: do we need to store this?
  useEffect(() => {
    setRequiredScopes(properties?.requiredScopes);
  }, [properties]);

  useEffect(() => {
    setCurrentPathname(navigation?.location.pathname);
  }, [navigation]);

  useEffect(() => {
    (async function () {
      const userScopes = await getAccessPolicies(
        authProvider.createOrgedAuthProvider()
      );
      setScopeList(userScopes);
    })();
  }, [authProvider]);

  useEffect(() => {
    setHasScopes(
      requiredScopes?.every(
        (el) => scopeList.includes(el) && scopeList.length > 0
      )
    );
  }, [requiredScopes, scopeList]);

  useEffect(() => {
    function resizeCallback() {
      const newWidth = window.innerWidth;
      setIsMobile(newWidth <= MOBILE_WIDTH);
    }
    window.addEventListener('resize', resizeCallback);

    resizeCallback();
    return () => {
      window.removeEventListener('resize', resizeCallback);
    };
  }, []);

  useEffect(() => {
    const removeListener = navigation?.listen(({ pathname }) => {
      setCurrentPathname(pathname);
    });

    return () => {
      removeListener();
    };
  }, [navigation]);

  // Rule #1: The component was hidden to this page on Manifest
  const isHidden = useMemo(() => {
    const _pathAsRegEx: RegExp[] =
      properties?.hideOn?.path?.map((uri) => new RegExp(uri)) || [];
    return !!_pathAsRegEx.find((regex) => regex.test(currentPathname));
  }, [currentPathname, properties?.hideOn?.path]);

  // Rule #2: The component was disabled to this page on Manifest
  const isDisabled = useMemo(() => {
    const _disableOnAsRegEx: RegExp[] =
      properties?.disableOn?.map((uri) => new RegExp(uri)) || [];
    return !!_disableOnAsRegEx.find((regex) => regex.test(currentPathname));
  }, [currentPathname, properties?.disableOn]);
  // We don't use return here to avoid excessive mount/unmount on CustomerSelectorBox.

  // Rule #3: The user doesn't have the right scopes
  if (!hasScopes) {
    return null;
  }

  if (isMobile) {
    return (
      <CustomerAnchor
        isHidden={isHidden}
        isDisabled={isDisabled}
        properties={properties}
      ></CustomerAnchor>
    );
  }

  return (
    <CustomerSelectorBox
      isHidden={isHidden}
      isDisabled={isDisabled}
      properties={properties}
    ></CustomerSelectorBox>
  );
};

export default CustomerSelector;
